.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto;

  label {
    width: 5rem;
    display: inline-block;
    margin-bottom: 2px;
  }

  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
    font-family: "Open Sans", serif;
  }

  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
    min-height: 100px;
    font-family: "Open Sans", serif;
  }

  button {
    margin-top: 2rem;
    display: block;
    width: auto;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
}
