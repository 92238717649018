.fullScreenHeader {
  width: 100%vw;
  height: 100vh;
  background-image: url("../../../public/img/header.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}
.headerBox {
  width: 30%;
  padding-bottom: 20rem;
}

.headline {
  font-size: 2rem;
  font-weight: 400;
}
